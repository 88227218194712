import LoginRoutes from "./routes/login";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import AppRoutes from "./routes";

function App() {
    return (
        <Router>
            <div className="App">
                <AppRoutes />
            </div>

            <ToastContainer position='bottom-right' theme='colored' containerId='form' />
        </Router>
    )
};

export default App;
