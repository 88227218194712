import api from "..";

async function postNotas(data) {
  return await api
    .post('fichaAvaliacao', data)
    .then(async (res) => {
      const { data } = res;
      return res.data;
    });
}

async function postNotaAluno(data) {
  return await api
    .post('fichaAvaliacao/aluno', data)
    .then(async (res) => {
      const { data } = res;
      return res.data;
    });
}

async function postNotaLinha(data) {
  return await api
    .post('fichaAvaliacao/notas', data)
    .then(async (res) => {
      const { data } = res;
      return res.data;
    });
}

async function postQuantidadeAula(data) {
  return await api
    .post('fichaAvaliacao/aulasDadas', data)
    .then(async (res) => {
      const { data } = res;
      return res.data;
    });
}

async function listarLancamentosTurma(turmaId, disciplinaId) {
  return await api
    .get(`fichaAvaliacao?turmaId=${turmaId}&disciplinaId=${disciplinaId}`)
    .then(async (res) => {
      const { data } = res;
      return data;
    });
};

async function listarLancamentosTurmaInfantil(turmaId) {
  return await api
    .get(`fichaAvaliacao/infantil?turmaId=${turmaId}`)
    .then(async (res) => {
      const { data } = res;
      return data;
    });
};

async function listarLancamentosTurmaPrimeiroAno(turmaId) {
  return await api
    .get(`fichaAvaliacao/primeiroano?turmaId=${turmaId}`)
    .then(async (res) => {
      const { data } = res;
      return data;
    });
};


async function listarAlunos(turmaId, trimestreId, disciplinaId) {
  return await api
    .get(`fichaAvaliacao/alunos?turmaId=${turmaId}&trimestreId=${trimestreId}&disciplinaId=${disciplinaId}`)
    .then(async (res) => {
      const { data } = res;
      return data;
    });
};

async function listarAulas(turmaId, trimestreId, disciplinaId) {
  return await api
    .get(`fichaAvaliacao/aulasDadas?turmaId=${turmaId}&trimestreId=${trimestreId}&disciplinaId=${disciplinaId}`)
    .then(async (res) => {
      const { data } = res;
      return data;
    });
};

export {
    postNotas,
    listarLancamentosTurma,
    listarAlunos,
    postNotaAluno,
    postQuantidadeAula,
    listarAulas,
    listarLancamentosTurmaInfantil,
    listarLancamentosTurmaPrimeiroAno,
    postNotaLinha
}