import { useEffect, useState } from 'react'
import { useTable } from 'react-table'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import * as S from './styles'

function Table({
  columns,
  data,
  hasPagination,
  limit = 20,
  setLimit,
  skip = 0,
  setSkip,
  withSelectOption,
  setRow,
  selected,
  totalItems,
  isBoardTable = false,
  hiddenColluns = []
}) {
  const [isSelected, setIsSelected] = useState(false)
  const [itemsPerPage, setItemsPerPage] = useState(
    limit === 0 ? 10 : limit
  )
  const [currentPage, setCurrentPage] = useState(1)
  const [buttonsAmount, setButtonsAmount] = useState([])
  const [newData, setNewData] = useState(data ? data : [])

  const checkPageAmount = () => {
    const pageCount = Math.ceil(totalItems / itemsPerPage)
    const buttons = []

    for (let i = 1; i <= pageCount; i++) {
      buttons.push(i)
    }
    if (buttons.length === 0) {
      buttons.push(1)
      setCurrentPage(1)
    }
    setButtonsAmount(buttons)
  }

  const parseData = async () => {
    setNewData(data)
  }

  useEffect(() => {
    parseData()
    checkPageAmount()
  }, [itemsPerPage, totalItems, data])

  const handlePageNumber = (page) => {
    setCurrentPage(page);
    setSkip((page - 1) * limit);
  }

  const handleAmountView = (amount) => {
    setItemsPerPage(amount);
    setLimit(amount);
  }

  const handleSelect = row => {
    setRow(row)
    check(row)
  }

  const check = row => {
    if (row === selected) {
      setIsSelected(true)
      return { backgroundColor: '#4bad48' }
    }
    return {}
  }

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: newData,
      initialState: {
        hiddenColumns: hiddenColluns
      }
    })

  return (
    <div className="table-responsive">
      <table
        className="table table-striped table-hover table-responsive"
        {...getTableProps()}
      >
        <thead>
          {headerGroups.map(headerGroup => (
            <tr className="text-center" {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return withSelectOption ? (
              <tr
                className="small text-center"
                {...row.getRowProps()}
                onClick={() => handleSelect(row.values)}
              >
                {/* {withSelectOption && (
                  <S.ContainerRadioButton key="row_by_column__withCheckbox">
                    <input
                      type="checkbox"
                      name=''
                      checked={selected == row.values}
                      onChange={() => teste(row.values)}
                    />
                    <span className="checkmark" />
                  </S.ContainerRadioButton>
                )} */}

                {row.cells.map(cell => {
                  return <td className="small text-center" {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            ) : (
              <tr className="small text-center" {...row.getRowProps()}>
                {/* {withSelectOption && (
                  <S.ContainerRadioButton key="row_by_column__withCheckbox">
                    <input
                      type="checkbox"
                      name=''
                      checked={selected == row.values}
                      onChange={() => teste(row.values)}
                    />
                    <span className="checkmark" />
                  </S.ContainerRadioButton>
                )} */}

                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
          {rows.length === 0 && (
            <tr>
              <td colSpan={columns.length} className="text-center">
                {isBoardTable ? 'Utilize os filtros para visualizar os quadros disponíveis' : 'Nenhum registro encontrado'}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {hasPagination && totalItems > 0 && (
        <S.PaginationSection>
          <S.SelectAmount>
            <S.SelectComponent
              onChange={e => handleAmountView(parseInt(e.target.value))}
              value={itemsPerPage}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
            </S.SelectComponent>{' '}
            itens de {totalItems} no total
          </S.SelectAmount>
          <S.SelectPage>
            Página:

            <S.NumberButton onClick={() => handlePageNumber(currentPage - 1)} disabled={currentPage === 1}>
              <NavigateBeforeIcon />
            </S.NumberButton>
            <S.SelectComponent
              onChange={e => handlePageNumber(parseInt(e.target.value))}
              value={currentPage}
            >
              {
                buttonsAmount.map(key => (
                  <option value={key} >{key}</option>
                ))
              }
            </S.SelectComponent>
            <S.NumberButton onClick={() => handlePageNumber(currentPage + 1)} disabled={currentPage === (buttonsAmount.length)}>
              <NavigateNextIcon />
            </S.NumberButton>
          </S.SelectPage>
        </S.PaginationSection>
      )}
    </div>
  )
}

export default Table
