import { useMemo, useState, useEffect } from 'react';
import BaseGestao from '../gestao/baseGestao';
import BaseGestaoUnidades from '../gestao-unidades/baseGestaoUnidades';
import BaseDocente from '../docente/baseDocente';
import BaseFichaAvaliacao from '../ficha-avaliacao/baseFichaAvaliacao';
import Breadcrumb from "../../components/breadcrumb";
import { useQuery } from 'react-query';
import Spinner from '../../components/spinner';
import { useNavigate } from "react-router-dom";

import handleDownloadExcel from '../../../src/utils/handleDownloadExcel';
import { getEscolaridadesUnidade } from '../../services/escolaridades';
import { getTurnosUnidadeEscolaridade } from '../../services/turnos';
import { getTurmasUnidadeEscolaridadeTurno } from '../../services/turmas';
import Table from "../../components/table";
import urlsGestao from '../gestao/urlsGestao';
import urlsGestaoUnidades from '../gestao-unidades/urlsGestaoUnidades';
import api from '../../services';
import { toast } from 'react-toastify';
import createStorageReportTurmas from './../../utils/reportStorageTurma';
import urls from './../../utils/urls';
import Select from 'react-select';
import {getUnidades} from '../../services/unidades'

const TurmasPage = (gestao) => {
  const navigate = useNavigate();

  let BaseComponent;
  let BasePath;
  let adicionarUrl;
  let alunosUrl;

  // Primeira condição para escolher entre BaseGestao e BaseGestaoUnidades
  if (gestao.gestao) {
    BaseComponent = BaseGestao;
    BasePath = 'Gestão'
    adicionarUrl = urlsGestao.turmasAdicionar;
    alunosUrl = urlsGestao.turmasAlunos;
  } 
  if (!gestao.gestao && !gestao.docente) {
    BaseComponent = BaseGestaoUnidades;
    BasePath = 'Gestão Unidades'
    adicionarUrl = urlsGestaoUnidades.turmasAdicionar;
    alunosUrl = urlsGestaoUnidades.turmasAlunos;
  }

  // Segunda condição para substituir BaseComponent por BaseDocente, se docente.docente for verdadeiro
  if (gestao.docente) {
    BaseComponent = BaseDocente;
    BasePath = 'Docente'
    adicionarUrl = urlsGestaoUnidades.turmasAdicionar;
    alunosUrl = urlsGestaoUnidades.turmasAlunos;
  }

  if (gestao.fichaAvaliacao) {
    BaseComponent = BaseFichaAvaliacao;
    BasePath = 'Ficha Avaliação'
    adicionarUrl = '';
    alunosUrl = '';
  }


  const anoLetivoAtual = process.env.REACT_APP_SECRETARIA_MUNICIPAL !== 'SEMED' ? 0 : 2024;
  const [unidades, setUnidades] = useState([])
  const [openPicker, setOpenPicker] = useState(false);
  const onMenuOpen = () => setIsOpen(true);
  const onMenuClose = () => setIsOpen(false);
  const [isOpen, setIsOpen] = useState(false);

  const [exportar, setExportar] = useState(0);
  const nomeArquivo = 'relatorio-turmas.xlsx'
  const filtroTurmaAnoLetivo = localStorage.getItem('filtroTurmasAnoLetivo') || anoLetivoAtual;
  const filtroTurmaEscolaridade = localStorage.getItem('filtroTurmasEscolaridade') || 0;
  const filtroTurmaTurno = localStorage.getItem('filtroTurmasTurno') || 0;

  const unidadeId = localStorage.getItem('unidadeID');
  const [unidade, setUnidade] = useState(gestao.gestao ? 0 : unidadeId)
  const [escolaridade, setEscolaridade] = useState(filtroTurmaEscolaridade);
  const [anoLetivo, setAnoLetivo] = useState(filtroTurmaAnoLetivo);
  const [turno, setTurno] = useState(filtroTurmaTurno);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(20);
  const [data, setData] = useState([]);

  const { isLoading: loadingUnidades, refetch: refetchUnidades } = useQuery('getUnidades', () => getUnidades(), {
    enabled: true,
    retry: true,
    onSuccess: (data) => {
      var dados = [{value: 0, label: 'TODOS'}];
      data.forEach(item => {
        dados.push({ value: item.id, label: item.nome })
      });

      setUnidades(dados);
    }
  })

  

  const anosLetivos = [
    { id: 2024, value: 2024 }
];

if (process.env.REACT_APP_SECRETARIA_MUNICIPAL === 'SEDUC') {
    anosLetivos.unshift({ id: 2023, value: 2023 });
}


  console.log(data)

  const { isError, isLoading, refetch } = useQuery(
    'getTurmas', () => getTurmasUnidadeEscolaridadeTurno(unidade, escolaridade, turno, anoLetivo, skip, limit),
    {
      retry: 0,
      enabled: false,
      onSuccess: (data) => {
        setData(data);
      }
    });


  const { data: escolaridades, isLoading: loadingEscolaridades, refetch: refetchEscolaridades } = useQuery(
    'getEscolaridades', () => unidade !== null ? getEscolaridadesUnidade(unidade) : null,
    {
      retry: 0,
      enabled: false,
    });

  const { data: turnos, isLoading: loadingTurnos, refetch: refetchTurnos } = useQuery(
    'getTurnos', () => unidade !== null && escolaridade !== null ? getTurnosUnidadeEscolaridade(unidade, escolaridade) : null,
    {
      retry: 0,
      enabled: false,
    });

  const cleanFilters = async (name) => {
    switch (name) {
      case 'unidade':
        await setEscolaridade(0);
        await setTurno(0);
        await localStorage.setItem('filtroTurmasEscolaridade', 0);
        await localStorage.setItem('filtroTurmasTurno', 0);
        break;
      case 'escolaridade':
        await setTurno(0);
        await localStorage.setItem('filtroTurmasTurno', 0);
        break;
      case 'turno':
        break;
      default:
        break;
    }

  }

  useEffect(() => {
    refetch()
    refetchEscolaridades()
  }, [refetch, limit, skip])



  const handleDelete = async (id) => {
    try {
      var response = await api.delete(`turma/${id}`)
      toast.success('Turma excluída com sucesso!')
      refetch()
    } catch (err) {
      toast.error('Falha na exclusão da turma. Tente novamente mais tarde!')
    }
  }
  
  const clickImprimirTurmas = async () => {
    var data = await getTurmasUnidadeEscolaridadeTurno(unidade, escolaridade, turno, anoLetivo, skip, 5000);
    var listaDados = [];
    data.data.map(x => {
      listaDados.push({ id: x.id,
        unidadeId: x.unidadeId,
        escolaridadeId: x.escolaridadeId,
        turnoId: x.turnoId, 
        nomeTurma :x.descricao, 
        vagasTotais: x.vagasTotais, 
        vagasRegulares: x.vagas, 
        vagasPcd: x.vagasPcd, 
        alunosRegulares: x.quantidadeAlunosRegulares, 
        alunosPcd: x.quantidadeAlunosPcdPne, 
        saldo: x.saldo,
        })
    });

    await createStorageReportTurmas({dados: listaDados, escolaridade: escolaridade, unidade: unidade, todasUnidades: unidades});
    
    window.open(urls.relatorio, '_blank');

  }
  
  const exportarDados = async () => {
    
    if(data.length === 0 || data?.total === 0){
      return toast.warning('Não há dados para exportar.')
    }
  
    try {
  
    const relatorioData = await getTurmasUnidadeEscolaridadeTurno(unidade, escolaridade, turno, anoLetivo, skip, 5000, 1)
  
    if (relatorioData) {
      return handleDownloadExcel(relatorioData, nomeArquivo);
    } else {
        return toast.error('Falha ao obter o relatório');
        // Tratar falha na obtenção do relatório
    }
    } catch (error) {
      return toast.error('Erro ao buscar relatório');
      // Tratar erro na chamada da função
    }
   
    // return await handleDownloadExcel(relatorioData, titulo);
  }
  // Tabela
  const columns = useMemo(
    () => [
      { Header: '#', accessor: 'id' },
      { Header: 'Nome Turma', accessor: 'descricao' },
      { Header: 'PreMatricula', accessor: 'preMatricula' },
      { Header: 'Vagas Totais' , accessor: 'vagasTotais' },
      // { Header: 'Alunos', accessor: 'quantidadeAlunos' },
      // { Header: 'Sala (m²)', accessor: 'metragemSala' },
      // { Header: 'Ano Letivo', accessor: 'anoLetivo' },
      { Header: 'Vagas Regulares', accessor: 'vagas' },
      { Header: 'Vagas PcD', accessor: 'vagasPcd' },
      { Header: 'Alunos Regulares', accessor: 'quantidadeAlunosRegulares' },
      { Header: 'Alunos PcD', accessor: 'quantidadeAlunosPcdPne' },
      {
        Header: 'Saldo',
        accessor: 'saldo',
        Cell: ({ row }) => {

          let saldo = row.original.saldo
  
          return (
            <span style={{
              color: saldo < 0 ? 'red' : 'inherit',
              fontWeight: saldo < 0 ? 'bold' : 'normal',
            }}>
              {saldo}
            </span>
          );
        },
      },
      {
        Header: '', accessor: 'actions',
        Cell: ({ row }) => (
          <>
            {row.original.quantidadeAlunosRegulares === 0 && row.original.quantidadeAlunosPcdPne === 0 && gestao.gestao == true && (
                <button
                  onClick={() => handleDelete(row.original.id)}
                  className='btn btn-sm btn-danger d-block d-sm-inline-block'
                  
                >
                  <i className="material-icons-two-tone">remove</i> excluir
                </button>
            )}
            {!(gestao.fichaAvaliacao || gestao.docente) && (
  <button  
    className='btn btn-sm btn-primary d-block d-sm-inline-block'
    onClick={() => navigate(adicionarUrl, {
      state: {
        id: row.original.id,
        turma: {
          descricao: row.original.descricao,
          turnoID: row.original.turnoId,
          vagas: row.original.vagas,
          metragemSala: row.original.metragemSala,
          escolaridadeID: row.original.escolaridadeId,
          anoLetivo: row.original.anoLetivo,
          vagasPcd: row.original.vagasPcd,
          preMatricula: row.original.preMatricula,
          vagasTotais: row.original.vagasTotais
        },
      }
    })}
  >
    <i className="material-icons-two-tone">edit_note</i> visualizar
  </button>
)}

{!(gestao.fichaAvaliacao || gestao.docnete) && (
  <button  
    onClick={() => navigate(alunosUrl + row.original.id, {
      state: {
        id: row.original.id,
        turma: {
          descricao: row.original.descricao,
          turnoID: row.original.turnoId,
          vagas: row.original.vagas,
          metragemSala: row.original.metragemSala,
          escolaridadeID: row.original.escolaridadeId,
          anoLetivo: row.original.anoLetivo,
          vagasPcd: row.original.vagasPcd,
          preMatricula: row.original.preMatricula
        },
      }
    })}
    className='btn btn-sm btn-success d-block d-sm-inline-block'
  >
    <i className="material-icons-two-tone">people</i> alunos
  </button>
)}

{(gestao.fichaAvaliacao || gestao.docnete) && (
  <button  
    onClick={() => navigate(alunosUrl + row.original.id, {
      state: {
        id: row.original.id,
        turma: {
          descricao: row.original.descricao,
          turnoID: row.original.turnoId,
          vagas: row.original.vagas,
          metragemSala: row.original.metragemSala,
          escolaridadeID: row.original.escolaridadeId,
          anoLetivo: row.original.anoLetivo,
          vagasPcd: row.original.vagasPcd,
          preMatricula: row.original.preMatricula
        },
      }
    })}
    className='btn btn-sm btn-success d-block d-sm-inline-block'
  >
    <i className="material-icons-two-tone">edit_note</i> fichas
  </button>
)}

          </>
        )
      },
    ],
    [navigate]
  )

  function renderBreadcrumb() {
    if (gestao.gestao) {
      return (
        <Breadcrumb
          title={'Turmas'}
          itens={[BasePath, 'Turmas', 'Lista Geral']}
          actions={{ link: adicionarUrl, text: "Nova Turma" }}
          imprimir={{ link: urls.relatorio, text: "Imprimir" }}
          onClickImprimir={clickImprimirTurmas}
          hasExcelButton
          onClickDownload={() => exportarDados()} 
        />
      );
    } else {
      return (
        <Breadcrumb
          title={'Turmas'}
          itens={[BasePath, 'Turmas', 'Lista Geral']}
        />
      );
    }
  }

  
  
  


  return (
    <BaseComponent>
     {renderBreadcrumb()}
      <div className="row" style={{ marginBottom: 20 }}>

      <div className="col-12 col-md-4">
          <div className="form-group">
            <label htmlFor="unidadeId" className="form-label">
              Unidades
            </label>
            <Select
              aria-labelledby="aria-label"
              inputId="aria-example-input"
              name="aria-live-color"
              onMenuOpen={onMenuOpen}
              onMenuClose={onMenuClose}
              options={unidades}
              isDisabled={gestao.gestao ? false : true}
              value={unidades.find(x => x.value == unidade)}
              placeholder=''             
              onChange={async e => {
                await setUnidade(e.value);
                cleanFilters('unidade');
                refetchEscolaridades()
                refetch()
              }
              }
            />
          </div>
        </div>

      <div className="col-12 col-md-2">
          <div className="form-group">
            <label htmlFor="anoLetivo" className="form-label">Ano Letivo</label>
            <select
              className="form-select"
              id="anoLetivo"
              value={anoLetivo}
              onChange={async (e) => {
                await setAnoLetivo(e.target.value);
                localStorage.setItem('filtroTurmasAnoLetivo', e.target.value);
                refetch()
              }}
            >
              
                  <>
                    {process.env.REACT_APP_SECRETARIA_MUNICIPAL !== 'SEMED' && 
                    <option value={0}>TODOS</option>
                    }
                    {anosLetivos && anosLetivos.map((item) => (
                      <option key={item.id} value={item.id}>{item.value}</option>
                    ))}
                  </>
            </select>
          </div>
        </div>

        <div className="col-12 col-md-3">
          <div className="form-group">
            <label htmlFor="escolaridadeID" className="form-label">Escolaridades</label>
            <select
              className="form-select"
              id="escolaridadeID"
              value={escolaridade}
              onChange={async (e) => {
                await setEscolaridade(e.target.value);
                localStorage.setItem('filtroTurmasEscolaridade', e.target.value);
                cleanFilters('escolaridade');
                refetchTurnos();
                refetch();
              }}
            >
              {
                loadingEscolaridades ? (
                  <option value={0}>TODOS</option>
                ) : (
                  <>
                    <option value={0}>TODOS</option>
                    {escolaridades && escolaridades.map((item) => (
                      <option key={item.id} value={item.id}>{item.descricao}</option>
                    ))}
                  </>
                )
              }
            </select>
          </div>
        </div>

        <div className="col-12 col-md-3">
          <div className="form-group">
            <label htmlFor="turnoID" className="form-label">Turnos</label>
            <select
              className="form-select"
              id="turnoID"
              value={turno}
              onChange={async (e) => {
                await setTurno(e.target.value);
                localStorage.setItem('filtroTurmasTurno', e.target.value);
                refetch();
              }}>
              {
                loadingTurnos ? (
                  <option value={0}>TODOS</option>
                ) : (
                  <>
                    <option value={0}>TODOS</option>
                    {turnos && turnos.map((item) => (
                      <option key={item.id} value={item.id}>{item.descricao}</option>
                    ))}
                  </>
                )
              }
            </select>
          </div>
        </div>

      </div>
      {/* <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: 10, marginBottom: 20 }}>
        <div className='form-group' style={{ width: '100%', maxWidth: 300 }}>
          <input
            type='text'
            className='form-control'
            placeholder='Pesquisar'
            value={search}
            onChange={async (e) => await setSearch(e.target.value)} />
        </div>
        <div className='form-group' style={{ marginTop: 6, marginLeft: 10, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <button className='btn btn-sm btn-primary' onClick={() => handleSearch()}>
            <i className="material-icons-two-tone">search</i> Buscar
          </button>
        </div>
      </div> */}
      {
        <div className='row'>
          <div className='col-12'>
            <div className='card'>
              <Table
                columns={columns}
                data={data?.data ? data?.data : []}
                hasPagination
                limit={data?.limit}
                setLimit={setLimit}
                skip={data?.skip}
                setSkip={setSkip}
                totalItems={data?.total}
                hiddenColluns={['preMatricula']}
              />
            </div>
          </div>
        </div>
      }

    </BaseComponent >
  )
};
export default TurmasPage;
