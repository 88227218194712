import React from 'react';
import { Routes, Route } from 'react-router-dom';
import urlsFichaAvaliacao from '../../pages/ficha-avaliacao/urlsFichaAvaliacao';
import HomeFichaAvaliacao from '../../pages/ficha-avaliacao/homeFichaAvaliacao';
import TurmasPage from '../../pages/turmas';
import AlunosPage from '../../pages/alunos';

const defaultPermisson = false;

const enable = process.env.REACT_APP_SECRETARIA_MUNICIPAL === 'GT2' || process.env.REACT_APP_SECRETARIA_MUNICIPAL === 'R3MAIS';

const FichaAvaliacaoRoutes = () => (
    <React.Fragment>
    {enable && (
    <>
      <Route path={urlsFichaAvaliacao.dashboard} element={<HomeFichaAvaliacao  fichaAvaliacao={true} gestao={false  } />} />
      <Route path={urlsFichaAvaliacao.turmas} element={<TurmasPage gestao={false} fichaAvaliacao={true} />} />
      {/* <Route path={urlsFichaAvaliacao.alunos} element={<AlunosPage gestao={false} docente={true} />} />  */}
    </>
    )}
  </React.Fragment>
);

export default FichaAvaliacaoRoutes;
