import urlsGestaoUnidades from './urlsGestaoUnidades';

const links = (moduloGestaoUnidadesGrupoGestaoEducacional, moduloGestaoUnidadesGrupoAcademico, moduloGestaoUnidadesGrupoDiario, moduloGestaoUnidadesGrupoTransferencias) => {
  return (
    {
      gestao_educacional: {
        id: 'gestao_educacional',
        text: 'GESTÃO EDUCACIONAL',
        type: 'title',
        visible: moduloGestaoUnidadesGrupoGestaoEducacional == 1,
        itens: {
          dashboard: {
            id: 'dashboard',
            text: 'DASHBOARD',
            path: urlsGestaoUnidades.dashboard,
            icon: 'dashboard',
            visible: moduloGestaoUnidadesGrupoGestaoEducacional == 1,
          },
          servidores: {
            id: 'servidores',
            text: 'SERVIDORES',
            path: urlsGestaoUnidades.servidores,
            icon: 'group',
            visible: moduloGestaoUnidadesGrupoGestaoEducacional == 1,
          },
          calendario: {
            id: 'calendario',
            text: 'CALENDÁRIO',
            path: urlsGestaoUnidades.calendario,
            icon: 'badge',
            visible: moduloGestaoUnidadesGrupoGestaoEducacional == 1,
          }
        }
      },
      academico: {
        id: 'academico',
        text: 'ACADÊMICO',
        type: 'title',
        visible: moduloGestaoUnidadesGrupoAcademico == 1,
        itens: {
          alunos: {
            id: 'alunos',
            text: 'ALUNOS',
            path: urlsGestaoUnidades.alunos,
            icon: 'group',
            visible: moduloGestaoUnidadesGrupoAcademico == 1,
          },
         
          matricula: {
            id: 'matricula',
            text: 'MATRÍCULAS',
            path: urlsGestaoUnidades.matriculas,
            icon: 'group_add',
            visible:  moduloGestaoUnidadesGrupoAcademico == 1,
          },
          turmas: {
            id: 'turmas',
            text: 'TURMAS',
            path: urlsGestaoUnidades.turmas,
            icon: 'class',
            visible: moduloGestaoUnidadesGrupoAcademico == 1,
          },
          
         
        }
      },

      transferencias: {
        id: 'transferencias',
        text: 'TRANSFERÊNCIAS',
        type: 'title',
        visible: moduloGestaoUnidadesGrupoAcademico == 1,
        itens: {
          recebidas: {
            id: 'recebidas',
            text: 'RECEBIDAS',
            path: urlsGestaoUnidades.transferenciasRecebidas, //alterar nova url de gestao
            icon: 'groups',
            visible: moduloGestaoUnidadesGrupoAcademico == 1,
          },
          enviadas: {
            id: 'enviadas',
            text: 'ENVIADAS',
            path: urlsGestaoUnidades.transferenciasEnviadas, //alterar nova url de gestao
            icon: 'groups',
            visible: moduloGestaoUnidadesGrupoAcademico == 1,
          },
        }},
      
      
      diario: {
        id: 'diario',
        text: 'DIÁRIO',
        type: 'title',
        visible: moduloGestaoUnidadesGrupoDiario == 1,
        itens: {
          quadro_horario: {
            id: 'quadro_horario',
            text: 'QUADRO DE HORÁRIO',
            path: urlsGestaoUnidades.quadroHorario,
            icon: 'calendar_view_week',
            visible: moduloGestaoUnidadesGrupoDiario == 1,
          },
          
          // diario: {
          //   id: 'diario',
          //   text: 'DIÁRIO DE CLASSE',
          //   path: urlsGestaoUnidades.diarioClasse,
          //   icon: 'content_paste_go',
          //   visible: process.env.REACT_APP_SECRETARIA_MUNICIPAL !== 'SEMED' && moduloGestaoUnidadesGrupoDiario == 1,
          // },
          // faltas: {
          //   id: 'faltas',
          //   text: 'FALTAS',
          //   path: urlsGestaoUnidades.faltas,
          //   icon: 'spellcheck',
          //   visible: process.env.REACT_APP_SECRETARIA_MUNICIPAL !== 'SEMED' && moduloGestaoUnidadesGrupoDiario == 1,
          // },
          // fichaAvaliacao: {
          //   id: 'fichas',
          //   text: 'FICHAS DE AVALIAÇÃO',
          //   path: urlsGestaoUnidades.fichaAvaliacao,
          //   icon: 'collections_bookmark',
          //   visible: process.env.REACT_APP_SECRETARIA_MUNICIPAL === 'GT2' && moduloGestaoUnidadesGrupoDiario == 1,
          // },
          bnncFundamental:{
            id: 'bnnc-fundamentos',
            text: 'BNCC FUNDAMENTAL',
            path: urlsGestaoUnidades.bnccFundamental,
            icon: 'collections_bookmark',
            visible: true,
          },
        }
      },
      fichaAvaliacao: {
        id: 'fichaAvaliacao',
        text: 'FICHA AVALIAÇÃO',
        type: 'title',
        visible: process.env.REACT_APP_SECRETARIA_MUNICIPAL === 'GT2' && moduloGestaoUnidadesGrupoDiario == 1,
        itens: {
          fichaAvaliacaoInfantil: {
            id: 'fichaAvaliacaoInfantil',
            text: 'INFANTIL',
            path: urlsGestaoUnidades.fichaAvaliacaoInfantil,
            icon: 'collections_bookmark',
            visible: process.env.REACT_APP_SECRETARIA_MUNICIPAL === 'GT2' && moduloGestaoUnidadesGrupoDiario == 1,
          },
          fichaAvaliacaoPrimeiroAno: {
            id: 'fichaAvaliacaoPrimeiroAno',
            text: '1º ANO',
            path: urlsGestaoUnidades.fichaAvaliacaoPrimeiroAno,
            icon: 'collections_bookmark',
            visible: process.env.REACT_APP_SECRETARIA_MUNICIPAL === 'GT2' && moduloGestaoUnidadesGrupoDiario == 1,
          },
          fichaAvaliacaoSegundoAno: {
            id: 'fichaAvaliacaoSegundoAno',
            text: '2º ANO',
            path: urlsGestaoUnidades.fichaAvaliacaoSegundoAno,
            icon: 'collections_bookmark',
            visible: process.env.REACT_APP_SECRETARIA_MUNICIPAL === 'GT2' && moduloGestaoUnidadesGrupoDiario == 1,
          },
          fichaAvaliacaoTerceiroNonoAno: {
            id: 'fichaAvaliacaoTerceiroNonoAno',
            text: '3º ANO AO 9º ANO',
            path: urlsGestaoUnidades.fichaAvaliacaoTerceiroNonoAno,
            icon: 'collections_bookmark',
            visible: process.env.REACT_APP_SECRETARIA_MUNICIPAL === 'GT2' && moduloGestaoUnidadesGrupoDiario == 1,
          },
        }
      },    
     
    }
  )
};

export default links;