import Breadcrumb from '../../../../../components/breadcrumb'
import BaseChamadaPublica from '../../baseChamadaPublica'
import { listarFiltroUnidades, listarFiltroEscolaridades, listarInscricoes, postConvocar, listarTotalConvocados } from '../../../../../services/processos/chamadaPublica'
import { useNavigate } from 'react-router-dom'
import { useMemo, useEffect, useState, useRef } from 'react'
import { useQuery, useMutation } from 'react-query';
import urlsChamadaPublica from '../../urlsChamadaPublica'
import Table from '../../../../../components/table'
import { store } from '../../../../../store'
import { toast } from 'react-toastify'
import { getTurmasUnidadeEscolaridadeTurno } from '../../../../../services/turmas'
import Select from 'react-select';
import handleDownloadExcel from '../../../../../utils/handleDownloadExcel'

const ChamadaPublicInscritosListagemPagePreConvocacoes = (parametros) => {

  const navigate = useNavigate()
  const nomeArquivo = 'inscricoes-pre-lista-espera.xlsx'

  const [totalConvocados, setTotalConvocados] = useState(0)
  const [totalRemanejamentoEntrada, setTotalRemanejamentoEntrada] = useState(0)
  const [totalRemanejamentoSaida, setTotalRemanejamentoSaida] = useState(0)
  const [collunsToHide, setHiddenColumns] = useState([]);
  const [openPicker, setOpenPicker] = useState(false);
  const onMenuOpen = () => setIsOpen(true);
  const onMenuClose = () => setIsOpen(false);
  const [isOpen, setIsOpen] = useState(false);

  let unidadeFiltro = localStorage.getItem('chamadaPublicaUnidadeFiltroPC') === null ? 0 : localStorage.getItem('chamadaPublicaUnidadeFiltroPC');
  let escolaridadeFiltro =  localStorage.getItem('chamadaPublicaEscolaridadeFiltroPC') === null ? 0 :  localStorage.getItem('chamadaPublicaEscolaridadeFiltroPC');
  let statusFiltro = parametros.statusDefault;
  let deficienteFiltro =  localStorage.getItem('chamaPublicaDeficienteFiltroPC') === null ? 0 :  localStorage.getItem('chamaPublicaDeficienteFiltroPC');
  let redeFiltro =  localStorage.getItem('chamaPublicaRedeFiltroPC') === null ? 0 :  localStorage.getItem('chamaPublicaRedeFiltroPC');
  let nomeFiltro = localStorage.getItem('chamaPublicaNomeFiltroFiltroPC') === null ? '' :  localStorage.getItem('chamaPublicaNomeFiltroFiltroPC');

  const [data, setData] = useState([])
  const [unidade, setUnidade] = useState(unidadeFiltro)
  const [escolaridade, setEscolaridade] = useState(escolaridadeFiltro)
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(20);
  const [status, setStatus] = useState(1)
  const [deficiente, setDeficiente] = useState(deficienteFiltro)
  const [rede, setRede] = useState(redeFiltro)
  const [nome, setNome] = useState(nomeFiltro)


const currentUser = store.getState()['user']['user']

  const [unidadesData, setUnidadesData] = useState([])
  const [escolaridadesData, setEscolaridadesData] = useState([])


  


  const {
    data: unidades,
    isLoading: loadingUnidades,
    refetch: refetchUnidades
  } = useQuery(
    'listarFiltroUnidades',
    () => listarFiltroUnidades(parametros.parametros),
    {
      retry: 3,
      enabled: true,
      onSuccess: data => {
      var dados = [];
      data.forEach(item => {
        dados.push({ value: item.id, label: item.nome })
      });

      setUnidadesData(dados);
      }
    }
  )

  const {
    data: escolaridades,
    isLoading: loadingEscolaridades,
    refetch: refetchEscolaridades
  } = useQuery(
    'getEscolaridade',
    () => listarFiltroEscolaridades(parametros.parametros, unidade),
    {
      retry: 0,
      enabled: unidade > 0,
      onSuccess: data => {
        var dados = [];
        data.forEach(item => {
          dados.push({ value: item.id, label: item.nome })
        });
  
        setEscolaridadesData(dados);
        }
    }
  )

  const {
    data: turmas,
    isLoading: loadingTurmas,
    refetch: refetchTurmas
  } = useQuery(
    'getTurmas',
    () => getTurmasUnidadeEscolaridadeTurno(unidade, escolaridade, 0, 2024, 0, 20),
    {
      retry: 0,
      enabled: unidade > 0 && escolaridade > 0
    }
  )

  

  async function convocarAluno(inscricaoId, unidadeId, escolaridadeId) {
    // Confirm before proceeding
    
    const isConfirmed = window.confirm("Deseja realmente convocar este aluno?");
    
    if (!isConfirmed) {
      // User clicked 'Cancel', do nothing
      return;
    }

    
    const data = {
      usuarioId: parseInt(currentUser?.servidorId),
      unidadeId: parseInt(unidadeId),
      inscricaoId: inscricaoId,
    };

    if(unidadeId == 0 || escolaridadeId == 0){
      toast.warning('Selecione uma unidade para convocar o aluno.');
      return
    }
  
    const response = await postConvocar(data);


    if (response.statusCode !== 200) {
      toast.error('Erro ao convocar aluno.');
      return;
    }

    else{
      toast.success('Aluno convocado com sucesso.');
      refetchInscricoes();
    }

  
  }

  const { isError, isLoading, refetch: refetchInscricoes } = useQuery(
    'inscricoes',
    () =>listarInscricoes(unidade, escolaridade, status, deficiente, rede, skip,limit, nome),
    {
      retry: 0,
      enabled: unidade > 0 && escolaridade > 0,
      onSuccess: async data => {
        if(parametros.bloquearFiltros){
            try {
                const convocados = await listarTotalConvocados(unidade, escolaridade);
                if (convocados && convocados.data) {
                    setTotalConvocados(convocados.data.totalConvocados);
                    setTotalRemanejamentoEntrada(convocados.data.totalRemanejamentoEntrada);
                    setTotalRemanejamentoSaida(convocados.data.totalRemanejamentoSaida);
                }
            } catch (error) {
                console.error('Error fetching total convocados:', error);
            }
        }
        console.log(data);
        setData(data);
    }
  }    
  )

    const statusArray = 
  [
    // { id: 0, value: 'SEM STATUS' },
    { id: 1, value: 'LISTA DE ESPERA' },
    { id: 2, value: 'CONVOCADO' },
    { id: 3, value: 'MATRICULADO' },
    { id: 4, value: 'NÃO COMPARECIDO' },
    { id: 5, value: 'INDEFERIDO' },
    { id: 6, value: 'CANCELADO' },
    { id: 7, value: 'DESISTÊNCIA' },
    { id: 8, value: 'MATRICULADO POR OUTRA OPÇÃO' },
    { id: 9, value: 'CONVOCADO POR OUTRA OPÇÃO' },
    { id: 10, value: 'MATRICULADO EM OUTRA OPÇÃO' }
  ]

  const filtroComuns = 
  [
    { id: 0, value: 'TODOS' },
    { id: 1, value: 'SIM' },
    { id: 2, value: 'NÃO' },
  ]

  const handleStatusChange = async (e) => {
    const newStatus = parseInt(e.target.value);
    localStorage.setItem('chamaPublicaStatusFiltroPC', newStatus);
    setStatus(newStatus);
  };
  
  useEffect(() => {

    if (unidade > 0){
      refetchEscolaridades()
    }

    if (unidade > 0 && escolaridade > 0) {
      refetchInscricoes()
      refetchTurmas()
    }
    

  }, [unidade, escolaridade, limit, skip, nome, status, deficiente, rede, refetchInscricoes]);

  const exportarDados = async () => {
    
    if(data.length === 0 || data?.total === 0){
      return toast.warning('Não há dados para exportar.')
    }
  
    try {
  
    const relatorioData = await listarInscricoes(unidade, escolaridade, status, deficiente, rede, skip,5000, nome, 1)
  
    if (relatorioData) {
      return handleDownloadExcel(relatorioData, nomeArquivo);
    } else {
        return toast.error('Falha ao obter o relatório');
        // Tratar falha na obtenção do relatório
    }
    } catch (error) {
      return toast.error('Erro ao buscar relatório');
      // Tratar erro na chamada da função
    }
   
    // return await handleDownloadExcel(relatorioData, titulo);
  }

  const turmasColluns = useMemo(
    () => [
      { Header: '#', accessor: 'id' },
      { Header: 'Nome Turma', accessor: 'descricao' },
      { Header: 'PreMatricula', accessor: 'preMatricula' },
      { Header: 'Vagas Totais' , accessor: 'vagasTotais' },
      // { Header: 'Alunos', accessor: 'quantidadeAlunos' },
      // { Header: 'Sala (m²)', accessor: 'metragemSala' },
      // { Header: 'Ano Letivo', accessor: 'anoLetivo' },
      { Header: 'Vagas Regulares', accessor: 'vagas' },
      { Header: 'Vagas PcD', accessor: 'vagasPcd' },
      { Header: 'Alunos Regulares', accessor: 'quantidadeAlunosRegulares' },
      { Header: 'Alunos PcD', accessor: 'quantidadeAlunosPcdPne' },
      {
        Header: 'Saldo',
        accessor: 'saldo',
        Cell: ({ row }) => {

          let saldo = 0;

          if (process.env.REACT_APP_SECRETARIA_CIDADE === 'ARARUAMA') {
          const vagas = row.original.vagasTotais;
          const alunosRegulares = row.original.quantidadeAlunosRegulares;
          const alunosPcdPne = row.original.quantidadeAlunosPcdPne;
  
          saldo = vagas - (alunosRegulares + alunosPcdPne * 2);
          }

          if (process.env.REACT_APP_SECRETARIA_CIDADE === 'GT2') {
            const vagas = row.original.vagasTotais;
            const alunosRegulares = row.original.quantidadeAlunosRegulares;
            const alunosPcdPne = row.original.quantidadeAlunosPcdPne;
    
            saldo = vagas - (alunosRegulares + alunosPcdPne * 3);
            }
  
          return (
            <span style={{
              color: saldo < 0 ? 'red' : 'inherit',
              fontWeight: saldo < 0 ? 'bold' : 'normal',
            }}>
              {saldo}
            </span>
          );
        },
      },
    ],
    [navigate]
  )

  const columns = useMemo(
    () => [
      
      { Header: '#', accessor: 'id' },
      { Header: 'Opção', accessor: 'opcaoEscolhidaNumeral' },     
      { Header: 'Nome', accessor: 'nome' },
      { Header: 'Responsável', accessor: 'responsavelNomeCompleto' },
      {
        Header: 'Nascimento', accessor: 'dataNascimento',
        Cell: ({ row }) => (
          row.original.dataNascimento.split('T')[0].split('-').reverse().join('/')
        )
      },
      {
        Header: 'Criação', accessor: 'criacao',
        Cell: ({ row }) => (
          row.original.criacao.split('T')[0].split('-').reverse().join('/') + ' ' + row.original.criacao.split('T')[1].split('.')[0]
        )
      },
      {
        Header: 'Status', accessor: 'statusOpcaoEscolhidaDescricao',
        Cell: ({ row }) => (
          <span className={`badge bg-dark`}>
            {row.original.statusOpcaoEscolhidaDescricao}
          </span>
        )
      },
      {
        Header: 'Deficiente', accessor: 'deficiente',
        Cell: ({ row }) => (
          <span className={`badge bg-${row.original.deficiente == 1 ? 'success' : 'danger'}`}>
            {row.original.deficiente == 1 ? 'SIM' : 'NÃO'}
          </span>
        )
      },
      {
        Header: 'Estudante da Rede', accessor: 'estudanteDaRede',
        Cell: ({ row }) => (
          <span className={`badge bg-${row.original.estudanteDaRede == 1 ? 'success' : 'danger'}`}>
            {row.original.estudanteDaRede == 1 ? 'SIM' : 'NÃO'}
          </span>
        )
      },
      {
        Header: '',
        accessor: 'actions',
        Cell: ({ row }) => (
          <>

          <button
              onClick={() =>
                {  window.open(urlsChamadaPublica.visualizacao + row.original.id, '_blank')}
              }
              className="btn btn-sm btn-primary"
            >
              <i className="material-icons-two-tone">edit_note</i> visualizar
            </button> 
            
              <button onClick={() => navigate(urlsChamadaPublica.historico + '/' + row.original.id, {
                state: {
                  id: row.original.id,
                  unidade: row.original.opcaoEscolhidaNumeral,
                }
              })} className='btn btn-sm btn-warning'>
                <i className="material-icons-two-tone">edit_note</i> histórico
              </button>
              { parametros.bloquearFiltros && [257, 2429, 3029, 3030].includes(parseInt(currentUser?.servidorId)) && (
              <button
              onClick={async () =>
                await convocarAluno(row.original.id, row.original.opcaoEscolhida, row.original.escolaridade)
              }
              className="btn btn-sm btn-success"
            >
              <i className="material-icons-two-tone">edit_note</i> convocar
            </button> 
              )}
          </>
        )
      },
      
    ],
    [navigate]
  )


return (
    <BaseChamadaPublica>
      <Breadcrumb
        title={'Inscrições Para Convocação'}
        itens={['Chamada Pública', parametros.parametros == 1 ? 'Inscrições Creche' : 'Inscrições Pré', 'Lista Geral']}
        hasExcelButton
        onClickDownload={() => exportarDados()} 
      />


<div className="row" style={{ marginBottom: 20 }}>
<div className="col-12 col-md-4">
          <div className="form-group">
            <label htmlFor="unidadeId" className="form-label">
              Unidades
            </label>
            <Select
              aria-labelledby="aria-label"
              inputId="aria-example-input"
              name="aria-live-color"
              onMenuOpen={onMenuOpen}
              onMenuClose={onMenuClose}
              options={unidadesData}
              placeholder=''             
              onChange={async e => {
                setUnidade(e.value);
              }
              }
            />
          </div>
        </div>

        <div className="col-12 col-md-4">
          <div className="form-group">
            <label htmlFor="escolaridade" className="form-label">
            Escolaridades
            </label>
            <Select
              aria-labelledby="aria-label"
              inputId="aria-example-input"
              name="aria-live-color"
              onMenuOpen={onMenuOpen}
              onMenuClose={onMenuClose}
              options={escolaridadesData}
              placeholder=''             
              onChange={async e => {
                setEscolaridade(e.value);
              }
              }
            />
          </div>
        </div>

        <div className="col-12 col-md-4">
          <div className="form-group">
            <label htmlFor="status" className="form-label">
            Status
            </label>
            <select
              className="form-select"
              id="status" 
              value={status}
              disabled={parametros.bloquearFiltros}
              onChange={handleStatusChange}
            >

        <option value={0}></option>
              {statusArray?.map(item => (
                              <option key={item.id} value={item.id}>
                                {item.value}
                              </option>
                            ))}
               
            </select>
          </div>
        </div>

      </div>

      <div className="row" style={{ marginTop: 20, marginBottom: 40 }}>
      <div className="col-12 col-md-4">
          <div className="form-group">
            <label htmlFor="deficiente" className="form-label">
            Deficiente?
            </label>
            <select
              className="form-select"
              id="deficiente" 
              value={deficiente}
              disabled={parametros.bloquearFiltros}
              onChange={async e => {
                await setDeficiente(e.target.value)
                await localStorage.setItem('chamaPublicaDeficienteFiltroPC', e.target.value);
              }}
            >

      {/* <option value={0}>s</option> */}
              {filtroComuns?.map(item => (
                              <option key={item.id} value={item.id}>
                                {item.value}
                              </option>
                            ))}
               
            </select>
          </div>
        </div>

        <div className="col-12 col-md-4">
          <div className="form-group">
            <label htmlFor="estudanteDaRede" className="form-label">
            Estudante Da Rede?
            </label>
            <select
              className="form-select"
              id="estudanteDaRede" 
              value={rede}
              disabled={parametros.bloquearFiltros}
              onChange={async e => {
                await setRede(e.target.value)
                await localStorage.setItem('chamaPublicaRedeFiltroPC', e.target.value);
              }}
            >

            {/* <option value={0}></option> */}
              {filtroComuns?.map(item => (
                              <option key={item.id} value={item.id}>
                                {item.value}
                              </option>
                            ))}
               
            </select>
          </div>
        </div>

        <div className="col-12 col-md-12" style={{marginTop : '10px'}}>
        <div className='form-group'>
        <label htmlFor="regimeId" className="form-label">
              Pesquisar
            </label>
          <input
            type='text'
            className='form-control'
            placeholder='Pesquisar'
            value={nome}
            disabled={parametros.bloquearFiltros}
            onChange={e => setNome(e.target.value)}
          />
        </div>
        </div>

        {
  turmas && turmas.data && turmas.data.length > 0 && parametros.bloquearFiltros && (
    <div className="row" style={{marginTop: 20}}>
      <div className="col-12">
        <h4>Dados Turmas</h4>
        <div className="card">
          <Table
            columns={turmasColluns}
            data={turmas.data}
            hasPagination
            limit={limit}
            setLimit={setLimit}
            skip={skip}
            setSkip={setSkip}
            totalItems={turmas.total}
            hiddenColluns={['preMatricula']}
          />
        </div>
      </div>
    </div>
  )
}

        <div className='row' style={{marginTop: 20}}>
          <div className='col-12'>
            {
              parametros.bloquearFiltros &&(            
                <h4>Inscrições Lista Espera | Total Convocados:  <b>{totalConvocados}</b> | Total Remanejamento Recebidos:  <b>{totalRemanejamentoEntrada}</b> | Total Remanejamento Enviados:  <b>{totalRemanejamentoSaida}</b></h4>
              )}
              {
              parametros.bloquearFiltros == false &&(            
                <h4>Inscrições</h4>
              )}
       
            <div className='card'>
              <Table
                columns={columns}
                data={data?.data ? data?.data : []}
                hasPagination
                limit={data?.limit}
                setLimit={setLimit}
                skip={data?.skip}
                setSkip={setSkip}
                totalItems={data?.total}
                hiddenColumns={collunsToHide}
              />
            </div>
          </div>
        </div>

      </div>

   


      </BaseChamadaPublica>
)
}
export default ChamadaPublicInscritosListagemPagePreConvocacoes