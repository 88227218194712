import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as S from '../disciplinas/styles'
import Breadcrumb from "../../../components/breadcrumb";
import BaseGestao from "../../gestao/baseGestao";
import BaseGestaoUnidades from "../../gestao-unidades/baseGestaoUnidades";
import Table from "../../../components/table";
import urlsGestao from "../../gestao/urlsGestao";
import urlsGestaoUnidades from "../../gestao-unidades/urlsGestaoUnidades";
import { useMutation, useQuery } from 'react-query';
import FormInput from "../../../components/formInput";

import upperCaseSimple from "../../../utils/upperCaseSimple";
import removeEmpty from "../../../utils/removeEmpty";
import { getServidoresQuadroHorario, getDisciplinasDisponiveis, alocarDisciplina, editarDisciplinaAlocada, excluirDisciplinaAlocada, buscaAlocacoes } from "../../../services/servidores";



const daysOfWeek = [
  {
    id: 1,
    day: 'DOMINGO',
  },
  {
    id: 2,
    day: 'SEGUNDA-FEIRA',
  },
  {
    id: 3,
    day: 'TERÇA-FEIRA',
  },
  {
    id: 4,
    day: 'QUARTA-FEIRA',
  },
  {
    id: 5,
    day: 'QUINTA-FEIRA',
  },
  {
    id: 6,
    day: 'SEXTA-FEIRA',
  },
  {
    id: 7,
    day: 'SÁBADO',
  },
];



const QuadroDeHorarioAlocaDisciplinas = (gestao) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();
  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedRow, setSelectedRow] = useState('');
  const [alocationSelected, setAlocationSelected] = useState();
  const [disciplinasData, setDisciplinasData] = useState([]);

  const editarDisplinas = gestao.gestao ? urlsGestao.quadroHorarioEditarDisplina : urlsGestaoUnidades.quadroHorarioEditarDisplina;
  const BaseComponent = gestao.gestao ? BaseGestao : BaseGestaoUnidades;
  const BasePath = gestao.gestao ? 'Gestão' : 'Gestão Unidades';

  const unidadeId = JSON.parse(localStorage.getItem('unidadeID'))

  const { data: alocacoesData, refetch: alocacoesRefetch } = useQuery('buscaAlocacoes', () => id && buscaAlocacoes(id), {
    enabled: true,
    retry: true,
  })

  const { mutate, isLoading } = useMutation(alocationSelected ? editarDisciplinaAlocada : alocarDisciplina, {
    onSuccess: (data) => {
      toast.success('Operação executada com sucesso.');
      alocacoesRefetch();
    },
    onError: (error) => {
      if (error.response) {
        const { message } = error.response.data;
        toast.error(message);
        unselectUpdate()
      } else if (error.request) {
        toast.error('Erro ao tentar alocar uma disciplina, tente novamente mais tarde.');
        unselectUpdate()
      } else {
        toast.error(error.message);
        unselectUpdate()
      }
    }
  });

  const { mutate: excluirDisciplinaMutate } = useMutation(excluirDisciplinaAlocada, {
    onSuccess: (data) => {
      toast.success(data.message);
      alocacoesRefetch();
    },
    onError: (error) => {
      if (error.response) {
        const { message } = error.response.data;
        toast.error(message);
      } else if (error.request) {
        toast.error('Erro ao tentar excluir uma disciplina, tente novamente mais tarde.');
      } else {
        toast.error(error.message);
      }
    },
  })

  const { data: servidorData } = useQuery('getServidoresQuadroHorario', () => getServidoresQuadroHorario(unidadeId, 0, '', 5000, 0), {
    enabled: true,
    retry: {
      failureCount: 3
    },
  });

  const { data: data } = useQuery('getDisciplinasDisponiveis', () => getDisciplinasDisponiveis(), {
    enabled: true,
    retry: {
      failureCount: 3
    },
    onSuccess: (data) => {
      if (process.env.REACT_APP_SECRETARIA_MUNICIPAL === 'GT2'){

        debugger
        
          let escolaridade = localStorage.getItem('nomeEscolaridade');
          if (escolaridade && (escolaridade.includes('CRECHE') || escolaridade.includes('PRE') || escolaridade.includes('PRÉ'))) {
            data = data.filter(disciplina => [12, 13, 16, 17].includes(disciplina.id));
            setDisciplinasData(data);
            return
          }

          if (escolaridade && (escolaridade.includes('1º ANO'))) {
            data = data.filter(disciplina => [4,5,6,8,7,9,10].includes(disciplina.id));
            setDisciplinasData(data);
            return;
          }
          else{
            data = data.filter(disciplina => ![12,13,16,17].includes(disciplina.id));
            setDisciplinasData(data);
            return
          }
      }
      setDisciplinasData(data);
    }
  });

  const { register, handleSubmit, setValue, getValues, watch, formState: { isValid, errors } } = useForm({
    mode: "onChange",
    defaultValues: {
      quadroId: '',
      horaInicio: '',
      horaFim: '',
      disciplina: '',
      servidor: '',
      diaSemana: '',
    }
  });

  const onSubmit = () => {
    if (alocationSelected) { // edita a alocação
      const requestData = {
        quadroId: parseInt(id),
        horaInicio: getValues('horaInicio'),
        horaFim: getValues('horaFim'),
        disciplinaId: parseInt(getValues('disciplina')),
        servidor1Id: parseInt(getValues('servidor1')),
        servidor2Id: parseInt(getValues('servidor2')) || 0,
        diaSemana: parseInt(getValues('diaSemana')),
      }
      mutate({ alocationSelected, data: upperCaseSimple(removeEmpty(requestData)) });

    } else {
      const requestData = { // cadastra uma alocação
        quadroId: parseInt(id),
        horaInicio: getValues('horaInicio'),
        horaFim: getValues('horaFim'),
        disciplinaId: parseInt(getValues('disciplina')),
        servidor1Id: parseInt(watch('servidor1')),
        servidor2Id: parseInt(watch('servidor2')),
        diaSemana: parseInt(getValues('diaSemana')),
      }
      mutate(upperCaseSimple(removeEmpty(requestData)));
    }

    unselectUpdate();
  };

  const handleUpdateSelectedValue = (e, rowData) => {
    setSelectedValue(e);
    console.log('Row data: ', rowData);
    setSelectedRow(rowData);
    selectRowToUpdate(rowData)
  }

  const selectRowToUpdate = (rowData) => {
    setValue('diaSemana', rowData.diaSemana);
    setValue('disciplina', rowData.disciplinaID);
    setValue('horaInicio', rowData.horaInicio);
    setValue('horaFim', rowData.horaFim);
    setValue('servidor1', rowData.servidor1ID);
    setValue('servidor2', rowData.servidorID2);
    setAlocationSelected(rowData.id);
  }

  const unselectUpdate = () => {
    setValue('diaSemana', '');
    setValue('disciplina', '');
    setValue('horaInicio', '');
    setValue('horaFim', '');
    setValue('servidor1', '');
    setValue('servidor2', '');
    setSelectedRow('');
    setSelectedValue(null);
    setAlocationSelected();
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Selecionar', accessor: '',
        Cell: ({ row }) => (
          <S.CustomRadioInput
            type="radio"
            value={row.id}
            checked={selectedValue === row.id}
            onChange={(e) =>
              handleUpdateSelectedValue(e.target.value, row.original)}
          />
        )
      },
      { Header: 'ID Alocação', accessor: 'id' },
      { Header: 'Hora Início', accessor: 'horaInicio' },
      { Header: 'Hora Fim', accessor: 'horaFim' },
      { Header: 'Disciplina', accessor: 'disciplinaAbreviacao' },
      { Header: 'Dia da Semana', accessor: 'diaSemanaDescricao' },
      { Header: 'Servidor 1', accessor: 'servidorNome' },
      { Header: 'Servidor 2', accessor: 'servidor2Nome' },
      {
        Header: '', accessor: 'actions',
        Cell: ({ row }) => (

          <button onClick={() => {
            if (window.confirm('Deseja realmente excluir?')) {
              excluirDisciplinaMutate(row.original.id);
            }
          }} className='btn btn-sm btn-danger'>
            <i className="material-icons-two-tone">delete</i> excluir
          </button>
        )
      },
    ],
    [navigate, selectedValue]
  )

  // useMemo(() => {
  //   (async () => {
  //     if (state) {
  //       const { disciplina } = state;
  //       if (id && disciplina) {
  //         const { descricao, sigla, status } = disciplina;
  //         setValue('descricao', descricao);
  //         setValue('sigla', sigla);
  //         setValue('status', status);
  //       }
  //     }
  //   })();
  // }, [id, state, setValue]);



  return (
    <BaseComponent>
      {/* Card de alocação de disciplina */}
      <Breadcrumb title={'Alocação de Disciplinas'} itens={[BasePath, 'Acadêmico', 'Quadro de horários', 'Disciplinas', 'Alocação']} />
      <form id='form' onSubmit={handleSubmit(onSubmit)}>
        <div className='row'>
          <div className='col-12'>
            <div className='card'>
              <div className="card-body">
                <>
                  <div className='row'>
                    <div className='col-3' style={{marginTop:'7px'}}>
                      <FormInput
                        register={register}
                        mask="99:99"
                        errors={errors}
                        atribute="horaInicio"
                        label="Hora Início"
                      />
                    </div>

                    <div className='col-3' style={{marginTop:'7px'}}>
                      <FormInput
                        register={register}
                        mask="99:99"
                        errors={errors}
                        atribute="horaFim"
                        label="Hora Final"
                      />
                    </div>

                    <div className='col-3'>
                      <label htmlFor="disciplina" className="form-label">Disciplina<span style={{ color: 'red', marginLeft: '4px' }}>*</span></label>
                      <select className="form-select" id="disciplina" {...register("disciplina", { required: true })}>
                        <option value={0} ></option>
                        {disciplinasData?.sort((a, b) => a.descricao.localeCompare(b.descricao)).map(disciplina => (
                        <option key={disciplina.id} value={disciplina.id}>{disciplina.descricao}</option>
                      ))}


                      </select>
                      {errors.disciplina?.type === 'required' && <div role='alert' className="invalid-feedback d-block">Campo obrigatório!</div>}
                    </div>


                    <div className='col-3'>
                      <label htmlFor="diaSemana" className="form-label">Dia da semana<span style={{ color: 'red', marginLeft: '4px' }}>*</span></label>
                      <select className="form-select" id="diaSemana" {...register("diaSemana", { required: true })}>
                        <option value={0}></option>
                        {daysOfWeek?.map(dayOfWeek => (
                          <option key={dayOfWeek.id} value={dayOfWeek.id}>
                            {dayOfWeek.day}
                          </option>
                        ))}
                      </select>
                      {errors.diaSemana?.type === 'required' && <div role='alert' className="invalid-feedback d-block">Campo obrigatório!</div>}
                    </div>
                  </div>

                  <br />

                  <div className='row'>
                    <div className='col-6'>
                      <label htmlFor="servidor1" className="form-label">Servidor 1<span style={{ color: 'red', marginLeft: '4px' }}>*</span></label>
                      <select className="form-select" id="servidor1" {...register("servidor1", { required: true })}>
                        <option value={0}></option>
                        {servidorData?.data?.map(servidor => (
                          <option key={servidor.id} value={servidor.id}>
                            {servidor.nomeCompleto}
                          </option>
                        ))}

                      </select>
                      {errors.servidor?.type === 'required' && <div role='alert' className="invalid-feedback d-block">Campo obrigatório!</div>}
                    </div>
                    <div className='col-6'>
                      <label htmlFor="servidor2" className="form-label">Servidor 2</label>
                      <select className="form-select" id="servidor2" {...register("servidor2", { required: true })}>
                        <option value={0}></option>
                        {servidorData?.data?.map(servidor => (
                          <option key={servidor.id} value={servidor.id}>
                            {servidor.nomeCompleto}
                          </option>
                        ))}

                      </select>
                    </div>
                  </div>

                </>
              </div>
              <div className='card-footer text-end' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >
                <button type='button' className='btn btn-primary' style={{ justifySelf: 'flex-start' }} onClick={() => unselectUpdate()} >
                  Limpar seleção
                </button>
                <div style={{ display: 'flex', justifySelf: 'flex-end' }} >
                  <button type='button' className='btn btn-warning' style={{ marginRight: '8px' }} onClick={() => navigate(editarDisplinas + id)} >
                    Voltar
                  </button>
                  <button type='submit' className='btn btn-success'>
                    {isLoading ? 'Enviando...' : selectedRow !== '' ? 'Atualizar' : 'Alocar'}
                  </button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </form>

      {/* Table de disciplinas Alocadas */}

      <Breadcrumb title={'Alocações'} itens={[]} />
      <div className='row'>
        <div className='col-12'>
          <div className='card'>
            <div className="card-body">
              <Table columns={columns} data={alocacoesData ? alocacoesData : []} />
            </div>
          </div>
        </div>
      </div>
    </BaseComponent>
  )

};

export default QuadroDeHorarioAlocaDisciplinas;