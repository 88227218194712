import { useEffect } from 'react';
import { useForm, Controller } from "react-hook-form";
import { useMutation } from 'react-query'
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { postLogin } from '../../services/servidores';
import { Types as UserTypes  } from '../../store/ducks/user';
import urls from '../../utils/urls';
import SuporteRodape from '../../utils/suporteRodape';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.clear();
  }, [])

  const { mutate, isLoading } = useMutation(postLogin, {
    onSuccess: (data) => {
      if (data.statusCode == 404) {
        return toast.error(data.message);
      }
      dispatch({ type: UserTypes.PROFILE, payload: { user: data.data } });
      setTimeout(() => {
        navigate(urls.modulos);
      }, 500); // 1500 milliseconds = 1.5 seconds
    },
    onError: (error) => {
      if (error.response) {
        const { message } = error.response.data;
        toast.error(message);
      } else if (error.request) {
        toast.error('Erro ao tentar fazer login, tente novamente mais tarde.');
      } else {
        toast.error(error.message);
      }
    }
  });

  const { handleSubmit, control, formState: { errors }, watch } = useForm({
    defaultValues: {
      email: '',
      password: '',
    }
  });

  const handleLoginServidor = async ({ email, password }) => {
    mutate({ login: email.toUpperCase(), senha: password });
  };

  const handleLoginAluno = async () => {
    const email = watch('email');
    const password = watch('password');
    console.log({ email, password })
    toast.warn('Em breve seu acesso será liberado para acesso ao portal.')
  };

  const handleLoginResponsavel = async () => {
    const email = watch('email');
    const password = watch('password');
    console.log({ email, password })
    toast.warn('Em breve seu acesso será liberado para acesso ao portal.')
  };

  return (
    <div className="app app-auth-sign-in align-content-stretch d-flex flex-wrap justify-content-center">
       {/* <SuporteRodape /> */}
      <div className="app-auth-container">
        <div className='text-center auth-description'>
          <img className='p-3' src={`/assets/images/prefeituras/${process.env.REACT_APP_SECRETARIA_MUNICIPAL.toLowerCase()}.png`} alt='Logo' width='160' />
          <h6>{process.env.REACT_APP_PREFEITURA}</h6>
          <h4 className='text-dark'>SECRETARIA DE EDUCAÇÃO</h4>
          <p>
            Faça login na sua conta e continue para o painel.
          </p>
        </div>
        <p className="auth-description">
          Faça login na sua conta e continue para o painel.
        </p>
        <form>
          <div className="auth-credentials m-b-xxl">
            <label htmlFor="signInEmail" className="form-label">
              E-mail/Usuário
            </label>
            <Controller
              name="email"
              control={control}
              rules={{
                required: true
              }}
              render={({ field }) =>
                <input
                  className="form-control m-b-md"
                  id="signInEmail"
                  aria-describedby="signInEmail"
                  autoComplete="username"
                  placeholder="example@sistema.com"
                  {...field}
                />
              }
            />
            {errors.email && <p className="text-danger" style={{ marginTop: -10 }}>Digite o seu e-mail.</p>}
            <label htmlFor="signInPassword" className="form-label">
              Senha
            </label>
            <Controller
              name="password"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) =>
                <input
                  type="password"
                  className="form-control"
                  id="signInPassword"
                  aria-describedby="signInPassword"
                  autoComplete="current-password"
                  placeholder="******"
                  {...field}
                />
              }
            />
            {errors.password && <p className="text-danger" style={{ marginTop: 10 }}>Digite sua senha.</p>}
          </div>
          <div className="auth-submit">
            <button id='button_login' disabled={isLoading} type="submit" className="btn btn-primary float-end"
              onClick={handleSubmit(handleLoginServidor)}>
              {
                isLoading ? 'Entrando...' : 'Sou Servidor'
              }
            </button>
            {/* <button disabled={isLoading} className="btn btn-primary float-end" type="button"
              onClick={handleLoginAluno} style={{ margin: '0px 15px' }}>Sou Aluno
            </button> */}
            {/* <button disabled={isLoading} className="btn btn-primary float-end" type="button"
              onClick={handleLoginResponsavel}>Sou Responsável
            </button> */}
            {/* <a href="/" className="auth-forgot-password float-start">
              Não lembra da senha!?
            </a> */}
          </div>
        </form>
        <div className="divider" />
        <small className="text-left">
          Sistema de Gestão do Ecossistema Educacional | Versão - 2024.06.05.0015487
        </small>
        <small className="text-left">
          Todos os dados são de propriedade do cliente. Não faça uso indevido e
          nem mesmo divulgar/vender as informações no qual tem acesso.
        </small>
      </div>
    </div>
  );
};

export default Login;
